import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzMessageRef, NzMessageService } from 'ng-zorro-antd/message';
import { NzResultModule } from 'ng-zorro-antd/result';
import { NzSpinModule } from 'ng-zorro-antd/spin';

import { Component, inject } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  standalone: true,
  imports: [NzButtonModule, NzResultModule, RouterModule, NzCardModule, NzSpinModule],
  templateUrl: './offline.page.html',
  styleUrl: './offline.page.scss'
})
export class OfflinePage {
  private router = inject(Router);
  private messageService = inject(NzMessageService);

  get isOnline() {
    return window.navigator.onLine;
  }

  messageRef?: NzMessageRef;

  ngOnInit(): void {
    if (this.isOnline) this.router.navigateByUrl('/');
  }

  checkConnection() {
    if (!this.isOnline && !this.messageRef) {
      this.messageRef = this.messageService.error('Você ainda está sem conexão com a internet.');

      this.messageRef?.onClose.pipe(untilDestroyed(this)).subscribe(() => {
        this.messageRef = undefined;
      });
    }
  }
}
