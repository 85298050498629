import { Route } from '@angular/router';
import { eProduct } from '@ministrary/shared/enums/product.enum';
import { AuthGuard } from '@ministrary/shared/guards/auth.guard';
import { LoggedInGuard } from '@ministrary/shared/guards/logged-in.guard';
import { SubscriptionGuard } from '@ministrary/shared/guards/subscription.guard';
import { OfflinePage } from '@ministrary/shared/pages/offline/offline.page';

export const APP_ROUTES: Route[] = [
  {
    path: 'offline',
    title: 'Sem conexão',
    component: OfflinePage
  },
  {
    path: 'subscription',
    loadChildren: async () => (await import('./domain/subscription/subscription.routes')).SUBSCRIPTION_ROUTES
  },
  {
    path: 'plans',
    loadComponent: () => import('@ministrary/shared/components/plans/plans.component').then(m => m.PlansComponent)
  },
  {
    path: 'auth',
    canActivate: [LoggedInGuard],
    loadChildren: async () => (await import('@ministrary/auth/auth.routes')).AUTH_ROUTES
  },
  {
    path: 'complete-registration',
    title: 'Finalize seu cadastro',
    loadComponent: () => import('@core/pages/complete-registration/complete-registration.component').then(m => m.CompleteRegistrationComponent)
  },
  {
    path: 'link-church',
    title: 'Vincule uma igreja',
    loadComponent: () => import('@core/pages/link-church/link-church.page').then(m => m.LinkChurchPage)
  },
  { path: 'reset-password', title: 'Resetar senha', loadComponent: () => import('@core/pages/reset-password/reset-password.component').then(m => m.ResetPasswordComponent) },
  { path: 'qrcode-scanner', title: 'Escanear QR Code', loadComponent: () => import('@core/pages/qrcode-scanner/qrcode-scanner.page').then(m => m.QrcodeScannerPage) },
  { path: 'select-plan', title: 'Selecione um plano', loadComponent: () => import('@core/pages/select-plan/select-plan.page').then(m => m.SelectPlanPage) },
  {
    path: 'live',
    loadChildren: async () => (await import('./domain/event-live/event-live.routes')).EVENT_LIVE_ROUTES,
    canLoad: [AuthGuard, SubscriptionGuard]
  },
  {
    path: '',
    loadComponent: () => import('@core/layout/shell/shell.layout').then(m => m.ShellLayout),
    canLoad: [AuthGuard, SubscriptionGuard],
    canActivateChild: [AuthGuard, SubscriptionGuard],
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      { path: 'dashboard', title: 'Dashboard', loadComponent: () => import('./domain/kids/pages/kids-dashboard/kids-dashboard.page').then(m => m.KidsDashboardPage) },
      {
        path: 'settings',
        loadChildren: () => import('./domain/settings/settings.routes').then(m => m.SETTINGS_ROUTES)
      },
      {
        path: 'admin',
        loadChildren: async () => (await import('./domain/admin/admin.routes')).ADMIN_ROUTES
      },
      {
        path: 'kids',
        loadChildren: async () => (await import('./domain/kids/kids.routes')).KIDS_ROUTES,
        data: { products: [eProduct.KIDS] }
      },
      {
        path: 'volunteers',
        loadChildren: async () => (await import('./domain/volunteer/volunteer.routes')).VOLUNTEER_ROUTES
      },
      {
        path: 'calendar',
        loadChildren: async () => (await import('./domain/calendar/calendar.routes')).CALENDAR_ROUTES
      },
      {
        path: 'c/:code',
        title: 'Check-out',
        loadComponent: () => import('@core/components/check-out-redirect/check-out-redirect.component').then(m => m.CheckOutRedirectComponent)
      },
      {
        path: 'attendance',
        loadChildren: async () => (await import('./domain/attendance/attendance.routes')).ATTENDANCE_ROUTES
      },
      {
        path: 'unauthorized',
        title: 'Acesso negado',
        loadComponent: () => import('@ministrary/shared/pages/unauthorized/unauthorized.page').then(m => m.UnauthorizedPage)
      },
      {
        path: 'not-found',
        title: 'Página não encontrada',
        loadComponent: () => import('@ministrary/shared/pages/not-found/not-found.page').then(m => m.NotFoundPage)
      },
      {
        path: '**',
        pathMatch: 'full',
        redirectTo: 'not-found'
      }
    ]
  }
];
