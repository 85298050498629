@if (!isOnline) {
  <nz-result nzStatus="500" nzTitle="Você está offline!" nzSubTitle="Por favor, verifique sua conexão com a internet e tente novamente.">
    <div nz-result-extra>
      <button nz-button nzType="primary" [disabled]="!!messageRef" (click)="checkConnection()">Verificar conexão</button>
    </div>
  </nz-result>
} @else {
  <nz-card nzBorderless>
    <br />
    <nz-spin nzTip="Redirecionando..."></nz-spin>
    <br />
  </nz-card>
}
