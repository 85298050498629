import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@ministrary/shared/services/auth/auth.service';

export const AUTH_REDIRECT_URL = '/auth';

@Injectable({
  providedIn: 'root'
})
export class LoggedInGuard {
  private authService = inject(AuthService);
  private router = inject(Router);

  canActivate(): boolean {
    const isLoggedIn = this.authService.isAuthenticated;
    return isLoggedIn ? this.navigateToRoot() : true;
  }

  private navigateToRoot(): boolean {
    this.router.navigate(['/']);
    return false;
  }
}
