import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { eUserStatus } from '@ministrary/shared/enums/user-status.enum';
import { AuthService } from '@ministrary/shared/services/auth/auth.service';

import { ChurchService } from '../services/church/church.service';

export const AUTH_REDIRECT_URL = '/auth';
export const UNAUTHORIZED_REDIRECT_URL = '/unauthorized';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {
  private authService = inject(AuthService);
  private churchService = inject(ChurchService);
  private router = inject(Router);

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.handleAuthentication(next, state);
  }

  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.handleAuthentication(next, state);
  }

  canLoad(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.handleAuthentication(next, state);
  }

  handleAuthentication(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const isAuthenticated = this.authService.isAuthenticated;
    if (!isAuthenticated) {
      this.router.navigate([AUTH_REDIRECT_URL], { queryParams: { returnUrl: state.url } });
      return false;
    }

    const currentUser = this.authService.currentUser;
    if (currentUser.status === eUserStatus.pending) return !this.router.navigate(['/complete-registration'], { queryParamsHandling: 'merge' });
    if (currentUser.reset_password) return !this.router.navigate(['/reset-password'], { queryParamsHandling: 'merge' });

    if (!this.churchService.selectedChurch()) return !this.router.navigate(['/link-church'], { queryParamsHandling: 'merge' });

    if (!route.data) return true;

    const requiredProducts = route.data['products'];
    if (requiredProducts) {
      const hasRequiredProduct = this.churchService.hasProduct(requiredProducts);
      if (!hasRequiredProduct) {
        this.router.navigate([UNAUTHORIZED_REDIRECT_URL]);
        return false;
      }
    }

    const requiredRoles = route.data['roles'];
    const requiredMinistryRoles = route.data['ministryRoles'];
    if (!requiredRoles && !requiredMinistryRoles) return true;

    const hasRequiredRole = this.authService.hasRole(requiredRoles);
    const hasRequiredMinistryRole = this.authService.hasMinistryRole(requiredMinistryRoles);
    if (!hasRequiredRole && !hasRequiredMinistryRole) {
      this.router.navigate([UNAUTHORIZED_REDIRECT_URL]);
      return false;
    }

    return true;
  }
}
