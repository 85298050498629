import { HttpHandler, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthService } from '@ministrary/shared/services/auth/auth.service';

export class JwtInterceptor {
  private authService = inject(AuthService);

  intercept(request: HttpRequest<unknown>, next: HttpHandler) {
    const session = this.authService.session$.getValue();
    const token = session?.access_token;

    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
    }

    return next.handle(request);
  }
}
