import { ptBR } from 'date-fns/locale';
import { NzConfigService } from 'ng-zorro-antd/core/config';
import { NZ_DATE_LOCALE, NZ_I18N, pt_BR } from 'ng-zorro-antd/i18n';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { provideEcharts } from 'ngx-echarts';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { catchError, Subject, takeUntil, timeout, TimeoutError } from 'rxjs';

import { DatePipe, registerLocaleData, TitleCasePipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import pt from '@angular/common/locales/pt';
import { APP_INITIALIZER, Component, ErrorHandler, importProvidersFrom, inject, isDevMode, LOCALE_ID } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavigationCancel, NavigationEnd, provideRouter, Router, RouterModule, TitleStrategy, withComponentInputBinding, withInMemoryScrolling } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { FamilyService } from '@domain/kids/services/family.service';
import { environment } from '@env/environment';
import { provideGoogleAnalytics, provideGoogleAnalyticsRouter } from '@hakimio/ngx-google-analytics';
import { DEFAULT_COLOR } from '@ministrary/shared/constants/default-color.constants';
import { eUserStatus } from '@ministrary/shared/enums/user-status.enum';
import { GlobalErrorHandler } from '@ministrary/shared/handlers/global-error.handler';
import { AuthService } from '@ministrary/shared/services/auth/auth.service';
import { ChurchService } from '@ministrary/shared/services/church/church.service';
import { DownloadAvatarPipe } from '@ministrary/widget/pipes/download-avatar/download-avatar.pipe';
import { provideTransloco, TranslocoService } from '@ngneat/transloco';
import { JwtInterceptor } from '@shared/interceptors/jwt.interceptor';
import { PageTitleStrategy } from '@shared/strategies/page-title.strategy';

import { APP_ROUTES } from './app.routes';
import { TranslocoHttpLoader } from './transloco-loader';

registerLocaleData(pt);

function setDefaultTheme(nzConfigService: NzConfigService) {
  return () => nzConfigService.set('theme', { primaryColor: DEFAULT_COLOR });
}

function initConfiguration(authService: AuthService, churchService: ChurchService, familyService: FamilyService) {
  return async () => {
    if (authService.isOffline) return;

    await authService.load();
    if (!authService.isAuthenticated) return;
    if (authService.currentUser.status === eUserStatus.pending) return;

    await churchService.load();
    if (!churchService.selectedChurch()) return;
    await familyService.load();
  };
}

function checkConnection(router: Router) {
  return () => {
    if (!window.navigator.onLine) {
      if (window.location.pathname !== '/offline') router.navigateByUrl('/offline');
    }

    window.addEventListener('offline', () => {
      if (window.location.pathname !== '/offline') router.navigateByUrl('/offline');
    });

    window.addEventListener('online', () => {
      if (window.location.pathname === '/offline') {
        window.location.reload();
      }
    });
  };
}

function checkInternetSpeed(http: HttpClient, notificationService: NzNotificationService) {
  return () =>
    http
      .get(environment.baseUrl + '/assets/logo/logo.svg', { responseType: 'text' })
      .pipe(
        timeout(2000),
        catchError(error => {
          if (error instanceof TimeoutError) {
            notificationService.warning('Internet lenta!', 'As informações podem demorar para carregar.', {
              nzPlacement: 'top',
              nzDuration: 10000
            });
          }

          return [];
        })
      )
      .subscribe();
}

function loadLanguage(transloco: TranslocoService) {
  const defaultLang = transloco.getDefaultLang();
  return () => transloco.load(defaultLang);
}

@Component({
  standalone: true,
  imports: [RouterModule],
  selector: 'mt-root',
  template: `<router-outlet></router-outlet>`
})
export class AppComponent {
  private router = inject(Router);

  destroy$ = new Subject<void>();

  ngAfterViewInit() {
    const loadingScreen = document.getElementById('loading-screen');
    if (!loadingScreen) return;

    this.router.events.pipe(takeUntil(this.destroy$)).subscribe(event => {
      if (event instanceof NavigationEnd || event instanceof NavigationCancel) {
        loadingScreen.style.display = 'none';

        this.destroy$.next();
        this.destroy$.complete();
      }
    });
  }

  public static bootstrap() {
    bootstrapApplication(this, {
      providers: [
        { provide: APP_INITIALIZER, useFactory: checkInternetSpeed, multi: true, deps: [HttpClient, NzNotificationService] },
        { provide: APP_INITIALIZER, useFactory: checkConnection, multi: true, deps: [Router] },
        { provide: LOCALE_ID, useValue: 'pt-br' },
        { provide: NZ_DATE_LOCALE, useValue: ptBR },
        { provide: NZ_I18N, useValue: pt_BR },
        { provide: APP_INITIALIZER, useFactory: setDefaultTheme, multi: true, deps: [NzConfigService] },
        { provide: APP_INITIALIZER, useFactory: initConfiguration, multi: true, deps: [AuthService, ChurchService, FamilyService] },
        { provide: APP_INITIALIZER, useFactory: loadLanguage, multi: true, deps: [TranslocoService] },
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: TitleStrategy, useClass: PageTitleStrategy },
        {
          provide: 'environment',
          useValue: environment
        },
        provideRouter(
          APP_ROUTES,
          withComponentInputBinding(),
          withInMemoryScrolling({
            anchorScrolling: 'enabled',
            scrollPositionRestoration: 'enabled'
          })
        ),
        importProvidersFrom(HttpClientModule, BrowserAnimationsModule),
        provideTransloco({
          config: {
            availableLangs: ['pt-br'],
            defaultLang: 'pt-br',
            reRenderOnLangChange: true,
            prodMode: !isDevMode()
          },
          loader: TranslocoHttpLoader
        }),
        provideEnvironmentNgxMask(),
        provideEcharts(),
        provideGoogleAnalytics(environment.googleAnalyticsId),
        provideGoogleAnalyticsRouter(),
        NzNotificationService,
        TranslocoService,
        DownloadAvatarPipe,
        { provide: ErrorHandler, useClass: GlobalErrorHandler, deps: [NzNotificationService, TranslocoService] },
        importProvidersFrom(ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })),
        DatePipe,
        TitleCasePipe
      ]
    }).catch(err => console.error(err));
  }
}
