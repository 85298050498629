import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@ministrary/shared/services/auth/auth.service';

import { CheckoutApi } from '../apis/checkout.api';
import { eRoles } from '../enums/roles.enum';
import { ChurchService } from '../services/church/church.service';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionGuard {
  private authService = inject(AuthService);
  private checkoutApi = inject(CheckoutApi);
  private churchService = inject(ChurchService);
  private router = inject(Router);

  canActivate(): boolean {
    return this.handleSubscription();
  }

  canActivateChild(): boolean {
    return this.handleSubscription();
  }

  canLoad(): boolean {
    return this.handleSubscription();
  }

  private handleSubscription(): boolean {
    const isAdmin = this.authService.hasRole([eRoles.ADMIN]);
    if (!isAdmin) return true;

    const hasActiveSubscription = this.churchService.hasActiveSubscription;
    if (hasActiveSubscription) return true;

    this.checkoutApi.checkout();
    return false;
  }
}
