import { NzNotificationService } from 'ng-zorro-antd/notification';

import { ErrorHandler, inject, Injectable, isDevMode } from '@angular/core';
import { CustomError } from '@ministrary/shared/errors/custom.error';
import { LoadingService } from '@ministrary/shared/services/loading/loading.service';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({
  providedIn: 'root'
})
export class GlobalErrorHandler implements ErrorHandler {
  private notification = inject(NzNotificationService);
  private loadingService = inject(LoadingService);
  private translocoService = inject(TranslocoService);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public handleError(error: any): void {
    const normalizedError = this.normalizeError(error);

    if (normalizedError instanceof CustomError) this.handleCustomError(normalizedError);
    else if (isDevMode()) throw normalizedError;

    this.loadingService.stop();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private normalizeError(error: any): CustomError {
    if (error?.rejection) {
      error = error.rejection;
    }

    return error instanceof CustomError ? this.prepareError(error) : error;
  }

  private handleCustomError(error: CustomError): void {
    const preparedError = this.prepareError(error);
    this.notification.error(preparedError.title, preparedError.description!);
  }

  private prepareError(error: CustomError): CustomError {
    if (error.path && error.key) {
      const translation = this.getTranslation(`${error.path}.${error.key}`);
      return new CustomError({ title: translation.title, description: translation.description });
    }

    return error;
  }

  private getTranslation(translationKey: string): { title: string; description: string } {
    const translation = this.translocoService.translateObject(translationKey);
    return {
      title: translation.TITLE,
      description: translation.DESCRIPTION
    };
  }
}
